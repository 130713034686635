import * as React from "react";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Slider, Snackbar, Typography } from '@mui/material';

import { COLORS } from "./constants";
import { FormField } from "./forms/fields";
import { styles } from "./styles";

export function Notification({...props}) {
    const {notification, setNotification, ...other} = props;

    const {value, notify} = notification;
    const handleClose = () => {setNotification({value: "", notify: false});}

    return (
        <Snackbar 
            {...other}
            open={notify}
            message={value}
            onClose={handleClose}
            autoHideDuration={props.duration}
            anchorOrigin={{
                vertical: props.vertical || "top", 
                horizontal: props.horizontal || "right"
            }}
        />
    )
}

export function Loading({...props}) {
    const {loading} = props;

    const {load, message} = loading;    
    return (
        <Backdrop 
            open={load || false}
            sx={{
                zIndex: 10000,
                color: COLORS.WHITE 
            }}
            className="flex align-center column"
        >
            <Box sx={{width: "50%", margin: 2}}>
                <LinearProgress 
                    // color="success" 
                    style={{borderRadius: 10, height: 7}}
                    sx={{
                        backgroundColor: COLORS.GRAY,
                        '& .MuiLinearProgress-bar': {backgroundColor: COLORS.BLACK}
                    }}
                />
            </Box>
            <Typography fontSize={14}>{message}</Typography>
        </Backdrop>
    )
}

export function DoubleKnobSlider({...props}) {
    const { value, setValue, valueLabelFormat, minDistance, ...other } = props;

    const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) return
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };

    return (
        <Slider 
            {...other}
            disableSwap
            value={value}
            valueLabelDisplay="auto"
            onChange={handleChange}
            valueLabelFormat={valueLabelFormat}
        />
    )
}

export function PausePlayButton({...props}) {
    const {state, pause, play} = props;

    return (
        <>
        {state === "playing"? 
            <IconButton onClick={pause} style={{...props.style}}>
                <PauseCircleIcon style={{...props.pauseStyle}}/>
            </IconButton>
            : 
            <IconButton onClick={play} style={{...props.style}}>
                <PlayCircleIcon style={{...props.playStyle}}/>
            </IconButton>
        }
        </>
    )
}

export function useViewport() {
    const [width, setWidth] = React.useState(window.visualViewport?.width || window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.visualViewport?.width || window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width };
}

export function TrackInfo({...props}) {
    const {style, width, track, ...other} = props;

    return (
        <div className="flex column" style={{...style}}>
            <Typography 
                fontSize={other.titleFontSize || 16} 
                className="text-overflow-ellipsis no-scrollbar"
                style={{overflow: "hidden", width: width > 900? 300 : 150}} 
            >{track?.name || track?.title || ""}</Typography>
            <Typography 
                fontSize={other.artistsFontSize || 14} 
                className="text-overflow-ellipsis no-scrollbar"
                style={{overflow: "hidden", width: width > 900? 300 : 150}}
            >
                {`${track?.artists?.map((artist: any) => ` ${typeof artist === "string"? artist : artist.name}`) || ""}`}
            </Typography>
        </div>
    )
}

export function useScreenOrientation() {
    const [orientation, setOrientation] = React.useState(window.screen.orientation.type)
    const updateOrientation = () => setOrientation(window.screen.orientation.type)

    React.useEffect(() => {
        window.addEventListener(
            'orientationchange',
            updateOrientation
        )
        
        return () => {
            window.removeEventListener(
                'orientationchange',
                updateOrientation
            )
        }
    }, [])

    return orientation
}

export function Confirm({...props}) {
    const {
        title,
        content, 
        agreeText,
        handleAgree,
        disagreeText, 
        agreeAutoFocus,
        handleDisagree,
        open, 
        setOpen
    } = props

    return (
        <Dialog
            open={open} 
            onClose={() => setOpen(false)}
            PaperProps={{style: {...props.style}}}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button 
                    style={{backgroundColor: !agreeAutoFocus? COLORS.BLACK : COLORS.GRAY, color: !agreeAutoFocus? COLORS.GRAY : COLORS.BLACK, borderRadius: 0}}
                    onClick={handleDisagree} 
                    className="public-sans"
                    autoFocus={!agreeAutoFocus}
                >
                        {disagreeText}
                </Button>
                <Button 
                    style={{backgroundColor: agreeAutoFocus? COLORS.BLACK : COLORS.GRAY, borderRadius: 0}}
                    onClick={handleAgree} 
                    className="public-sans"
                    autoFocus={agreeAutoFocus}
                >
                        {agreeText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function DebugFormField({...props}) {
    const {label, value, onChange, inputStyle, style, labelStyle, ...other} = props;
    return (
        <FormField 
            variant="outlined" 
            label={label}
            value={value}
            inputStyle={{color: COLORS.GRAY, ...inputStyle}}
            style={{...styles.formField, border: `1px solid ${COLORS.GRAY}`, width: 150, ...style}} 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            labelStyle={{color: COLORS.GRAY, backgroundColor: COLORS.BLACK, padding: "0 5px 0 5px", ...labelStyle}}
            {...other}
        />
    )
}