import * as React from "react";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { Typography } from "@mui/material";

import CustomTable from "./table";
import { styles } from "../styles";
import { COLORS } from "../constants";
import { newZoneContext } from "../pages/newZone";
import { DebugFormField, PausePlayButton, TrackInfo } from "../support";
import { LinearPlay, Pause, Play, PlayFrom, PlayTrack, QueueTracks } from "../api/syb";
import { findNowPlayingHelper, getNextTracks, msToTimeString, resize, setDisplays, sleep } from "../utils";
import { debugInfoType, newNowPlayingType, newPlaybackType, spotifyPlaylist, spotifyTrackType, sybPlaylist, sybTrackType } from "../types";
import { NotificationContext } from "..";

function NewPlaylist({...props}) {
    const scheduledSlot = props.scheduledSlot;
    const zone = React.useContext(newZoneContext);
    const debugInfo: debugInfoType = props.debugInfo;
    const playback: newPlaybackType = props.playback;
    const sybPlaylist: sybPlaylist = props.sybPlaylist;
    const nowPlaying: newNowPlayingType = props.nowPlaying;
    const setDebugInfo: CallableFunction = props.setDebugInfo;
    const notification = React.useContext(NotificationContext);
    const spotifyPlaylist: spotifyPlaylist = props.spotifyPlaylist;
    const playlistIsPlaying = (sybPlaylist?.name?.trim() === nowPlaying?.playFrom?.playlist?.name?.trim()) || (sybPlaylist?.name?.trim() === nowPlaying?.playFrom?.schedule?.playlist?.name?.trim());
    const playableTrackCount = spotifyPlaylist?.tracks?.filter((_, i) => {
        const sybTrack = sybPlaylist?.tracks[i];
        if (sybTrack) return sybTrack.isAvailable && ((zone?.zone.filterExplicit && !sybTrack.explicit) || !zone?.zone.filterExplicit);
        return false
    }).length;

    const play = Play();
    const pause = Pause();
    const playFrom = PlayFrom();
    const playTrack = PlayTrack();
    const linearPlay = LinearPlay();
    const queueTracks = QueueTracks();

    const playPlaylist = async (start: number | undefined = undefined) => {
        if (spotifyPlaylist.tracks) {
            // if no starting track index is specified play the first track
            if (typeof start !== "number") start = findNowPlayingHelper(sybPlaylist, spotifyPlaylist?.tracks, nowPlaying?.track?.name, "first", zone?.zone.filterExplicit) as number | undefined;
            if (typeof start === "number")  {
                await playTrack({variables: {
                    soundZones: [zone?.zone.id],
                    playlistId: sybPlaylist?.id, 
                    sourceTrackIndex: start
                }});

                if (sybPlaylist) {
                    const nextTracks = getNextTracks(start, sybPlaylist, scheduledSlot);
                    console.log("[newPlaylist] (nextTrackId) >>", nextTracks);

                    const queueStatus = await queueTracks({variables: {soundZone: zone?.zone.id, tracks: nextTracks}});
                    console.log("[newPlaylist] (queueStatus) >>", queueStatus);
                    props.setScheduleQueued();
                }
            }
        }

        switch (zone?.zone.selectedPlaylistType) {
            case "sidebar":
                await playFrom({variables: {soundZone: zone?.zone.id, source: sybPlaylist?.id}});
                await linearPlay({variables: {id: zone?.zone.id}});
                break;
            case "schedule":
                await sleep(3000)
                await playFrom({variables: {soundZone: zone?.zone.id, source: zone?.zone.scheduleId}});
                break;
            default: break;
        }
    }

    return (
        <>
            <CustomTable 
                header={
                    <>
                    <Typography fontSize={24}>{zone?.zone.selectedPlaylistType === "sidebar"? spotifyPlaylist?.name : "AI Playlist"}</Typography>
                    <div 
                        className="width-100 flex justify-between align-center" 
                        style={{
                            ...styles.tableHeader, 
                            backgroundColor: playlistIsPlaying? COLORS.ORANGE : COLORS.BLACK
                        }}
                    >
                        <div className="flex align-center justify-between width-100">
                            <div className="flex align-center">
                                <img src={spotifyPlaylist?.cover} style={{height: "100%", width: 125, marginRight: 20}} alt=""/> 
                                <div className="flex column">
                                    <Typography fontSize={16} style={{color: COLORS.GRAY, marginRight: 15}}>{playableTrackCount} Playable Tracks</Typography>
                                    <Typography fontSize={14} style={{color: COLORS.GRAY, marginRight: 15}}>{"("}{spotifyPlaylist?.tracks?.length} Total Tracks{")"}</Typography>
                                </div>
                            </div>

                            {/* {zone?.zone.selectedPlaylistType === "schedule" && zone.zone.clearance === 1? 
                                <div className="flex align-center">
                                    <DebugFormField 
                                        label="Energy weight"
                                        value={debugInfo.energyWeight}
                                        onChange={(value: string) => setDebugInfo({energyWeight: value})}
                                        labelStyle={{backgroundColor: playlistIsPlaying? COLORS.ORANGE : COLORS.BLACK}}
                                    />
                                    <DebugFormField 
                                        label="Mood weight"
                                        value={debugInfo.moodWeight}
                                        onChange={(value: string) => setDebugInfo({moodWeight: value})}
                                        labelStyle={{backgroundColor: playlistIsPlaying? COLORS.ORANGE : COLORS.BLACK}}
                                    />
                                </div> : <></>
                            } */}

                            <PausePlayButton 
                                playStyle={{height: 50, width: 50}}
                                pauseStyle={{height: 50, width: 50}}
                                style={styles.playlistPlayPauseButton}
                                state={playlistIsPlaying? playback?.state : undefined}
                                pause={playlistIsPlaying? async () => await pause({variables: {id: zone?.zone.id}}) : undefined}
                                play={playlistIsPlaying? async () => await play({variables: {id: zone?.zone.id}}) : playPlaylist}
                            />
                        </div>
                    </div>
                    </>
                }

                rows={spotifyPlaylist?.tracks || []}
                cells={(track: spotifyTrackType, i: number) => {
                    const sybTrack: sybTrackType | undefined = sybPlaylist?.tracks[i];
                    
                    const isPlaying: boolean = playlistIsPlaying && ((nowPlaying.track?.uri?.trim() === track?.uri?.trim()) || (sybTrack?.title?.trim() === nowPlaying.track?.name?.trim()));
                    
                    const isAvailable: boolean = sybTrack? sybTrack.isAvailable : false;
                    const explicit: boolean = sybTrack? sybTrack.explicit : track.explicit;
                    
                    const activateTrack: boolean = isAvailable && ((zone?.zone.filterExplicit && !explicit) || !zone?.zone.filterExplicit);

                    return (
                        <div
                            className={`flex align-center justify-between ${isPlaying || !activateTrack? "" : "pointer clicked"}`}
                            style={{
                                ...styles.trackTableRow, 
                                marginTop: i === 0? 181 : 0,
                                backgroundColor: isPlaying? COLORS.BLACK : COLORS.GRAY,
                                color: !activateTrack? COLORS.LIGHT_GRAY : isPlaying? COLORS.GRAY : COLORS.BLACK,
                            }}
                            onClick={() => {
                                if (!isPlaying && activateTrack) playPlaylist(i);
                            }}
                        >
                            <div className="flex align-center"
                                onMouseOver={() => {
                                    if (!isPlaying && activateTrack) {
                                        setDisplays([`${sybPlaylist?.id}-${i}-playIcon`, `${sybPlaylist?.id}-${i}-index`], ["flex", "none"]);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (!isPlaying && activateTrack) {
                                        setDisplays([`${sybPlaylist?.id}-${i}-playIcon`, `${sybPlaylist?.id}-${i}-index`], ["none", "flex"]);
                                    }
                                }}
                                style={{padding: "0 7px 0 7px"}}
                            >
                                <PlayCircleIcon style={{height: 30, width: 30, margin: `0 ${i > 9? 23 : 30}px 0 -${i > 9? 3 : 10}px`, display: isPlaying? "flex" : "none"}} id={`${sybPlaylist?.id}-${i}-playIcon`}/> 
                                <Typography fontSize={16} style={{margin: "0 20px 0 0", width: 30, display: isPlaying? "none" : "flex"}} id={`${sybPlaylist?.id}-${i}-index`}>{i + 1}</Typography>
                                
                                <TrackInfo track={sybTrack}/>
                            </div>
                            <div className="flex align-center">
                                {explicit? 
                                    <Typography style={{...styles.explicitTrack}} fontSize={12}>Explicit</Typography> : <></>
                                }
                                {!isAvailable? 
                                    <Typography style={{...styles.notAvailableTrack}} fontSize={12}>Not Available</Typography> : <></>
                                }
                            </div>
                            {/* {zone?.zone.selectedPlaylistType === "schedule" && zone.zone.clearance === 1?
                            <>
                            <Typography style={{padding: "3px 5px 0px 5px", width: 100}} fontSize={12}>Energy: {track.features.energy}</Typography>
                            <Typography style={{padding: "3px 5px 0px 5px", width: 100}} fontSize={12}>Mood: {track.features.valence}</Typography>
                            </> : <></>
                            } */}
                            <Typography>{msToTimeString(track.duration)}</Typography>
                        </div>
                    )
                }}

                backgroundColor={COLORS.GRAY}
                style={{...styles.playlistTable(zone?.width, zone?.orientation), ...props.style}}
                headerStyle={{backgroundColor: COLORS.GRAY, position: "fixed", ...props.headerStyle, width: resize(zone?.width, zone?.orientation)? "calc((100% - 225px)*0.90)" : "90%"}}
            />
        </>
    )
}

export default NewPlaylist;
